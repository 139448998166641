<template>
  <div>
    <p class="compont_p">会员资质证件上传</p>
    <div class="flex_row" style="flex-wrap: wrap">
      <div class="item" v-for="item in list" :key="item.key">
        <upload :url="item.url" :uploadkey="item.key" @successUrl="successUrl" @deleteImage="deleteImage" />
        <!-- <p>{{ item.title }} <span style="padding:2px 5px;background-color: coral;border-radius: 3px;color: #FFF;cursor:pointer" v-if="item.key=='cgwts' && item.url" @click="down(item.file_url)">下载</span></p> -->
        <p>{{ item.title }} <span style="padding:2px 5px;background-color: coral;border-radius: 3px;color: #FFF;cursor:pointer" v-if="item.key=='cgwts'" @click="down(item.file_url)">下载</span></p>
        <p>到期时间：{{ item.date }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/components/upload.vue";
export default {
  components: {
    upload,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$api("account.getCertificate").then((res) => {
      this.list = res.data;
    });
  },
  methods: {
    successUrl(data, key) {
      console.log(data);
      this.$api("account.updateCertificate", { [key]: data.id }).then(() => {
        this.$message.success("上传成功");
      });
    },
    down(url){
      if(url!=''){
        window.location.href = url;
      }
    },
    deleteImage(key) {
      this.$api("account.updateCertificate", { [key]: "del" }).then(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  color: @themeColor!important;
}

.item {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  p {
    font-weight: 400;
    color: #333333;
  }
  & > p:last-child {
    color: #666;
  }
}
</style>
